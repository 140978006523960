/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title }) => {
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
       }
     `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    lang = lang || 'en'

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:author`,
                    content: site.siteMetadata?.author,
                },
                // {
                //     name: `twitter:card`,
                //     content: `summary`,
                // },
                // {
                //     name: `twitter:creator`,
                //     content: site.siteMetadata?.social?.twitter || ``,
                // },
                // {
                //     name: `twitter:title`,
                //     content: title,
                // },
                // {
                //     name: `twitter:description`,
                //     content: metaDescription,
                // },
            ].concat(meta)}
        />
    )
}

 Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }

 Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }

export default Seo
